import React, { useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"

//link
import { AnchorLink } from "gatsby-plugin-anchor-links"
//form
// import WebriQForm from "@webriq/gatsby-webriq-form"

//lazyloading
import { LazyLoadComponent } from "react-lazy-load-image-component"

//images
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import HeroImg from "@images/ff-mark-v2.5-website.png"
// import VideoIcon from "@images/video-icon-sm.svg"
// import ImagesIcon from "@images/images-icon.svg"

//icons
import HeartIcon from "@images/heart-icon.svg"
// import CircleRightArrow from "@images/circled-arrow-right-white.svg"

const slugify = require("slugify")

const IndexPage = ({ location, data }) => {
  const aboutUsColBg = getImage(data.aboutUsCol)
  const whyUsColBg = getImage(data.whyUsCol)
  const ourEmblemColBg = getImage(data.ourEmblemCol)

  const posts = data.allSanityPost.edges
  const featuredPosts = data.allSanityFeaturedpost.edges[0].node.post

  console.log("trying to see the array of posts", posts)
  console.log("trying to see the array of featured", featuredPosts)

  // const filterFeatured = data.categoryLists.group.filter(
  //   fltr => fltr.fieldValue === "Featured"
  // )

  const filterResearch = data.categoryLists.group.filter(
    fltr => fltr.fieldValue === "Thought Provoking"
  )

  // const filterTrending = data.categoryLists.group.filter(
  //   fltr => fltr.fieldValue === "Trending"
  // )

  const featuredCat = data.allSanityCategory.nodes

  const videoRef = useRef()
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.5
  }

  return (
    <Layout location={location}>
      <Seo
        title="FLUO Foundation | Youth Mental Health"
        description="Inspiring our Youth to Feel Alive!"
      />
      {/* <div className="page-wrapper"> */}
      <div className="homepage-hero">
        <div className="container">
          <div
            className="text-center text-md-start d-md-flex align-items-md-center"
            style={{ marginBottom: "6rem" }}
          >
            <div>
              <img
                className="d-block img-fluid mx-auto"
                src={HeroImg}
                alt="Logo Mark"
                width="186px"
              />
            </div>
            <div className="px-4">
              <h1>
                <span
                  className="d-block text-white"
                  style={{ fontWeight: 700 }}
                >
                  The
                </span>
                <span
                  className="d-block display-3 text-white"
                  style={{ fontWeight: 900 }}
                >
                  FLUO Foundation
                </span>
              </h1>
              <span
                className="display-6 mt-4 d-block text-white fst-italic"
                style={{ fontWeight: 600 }}
              >
                Inspiring Our Youth to Feel Alive
              </span>
            </div>
          </div>
          <LazyLoadComponent>
            <div className="row align-items-center">
              <div className="col-md-6">
                <div
                  className="hero-form-wrapper p-5"
                  style={{ borderRadius: "25px" }}
                >
                  <h2 className="display-6 fw-bold text-white">
                    Stay Connected!
                  </h2>
                  {/* <p className="lead text-white" style={{ fontWeight: 600 }}>
                    We know you're ready. Who's with us?
                  </p> */}
                  <p
                    className="lead text-white mb-1 mt-3"
                    style={{ fontWeight: 600, lineHeight: "150%" }}
                  >
                    We're Building the Foundation for a Revolution.
                  </p>
                  <p className="lead text-white" style={{ fontWeight: 600 }}>
                    Join us as we rally behind our youth?
                  </p>
                  {/* <div className="klaviyo-form-wrapper"> */}
                  {/*if form is replaced, update selectors in forms.scss accordingly*/}
                  {/* <div className="klaviyo-form-U28DPb"></div> */}
                  <iframe
                    title="Stay Connected"
                    scrolling="no"
                    id="myIframe"
                    src="https://www.forms.fluofoundation.org/u28dpb"
                    allowTransparency="true"
                    style={{
                      border: "none",
                      width: "100%",
                      height: "300px",
                      background: "none",
                    }}
                  ></iframe>
                  {/* </div> */}
                  <div className="row">
                    <div className="col-12 mb-3">
                      <p className="mb-0 mt-3 text-white">
                        The FLUO Foundation uses the information you provide
                        here to contact you about future events, provide
                        reports, and other organization updates. You may
                        unsubscribe from these communications at any time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="px-3 px-lg-5 py-5 text-center">
                  <p
                    className="lead text-white mb-5"
                    style={{ fontWeight: 500 }}
                  >
                    {/* The FLUO Foundation is a community-galvanized 501(c)(3)
                    nonprofit devoted to educating, inspiring, and fostering a
                    greater understanding and comprehensive implementation of
                    children, teen, and young adult mental health needs and
                    services. */}
                    As a community-galvanized 501(c)(3) nonprofit, the FLUO
                    Foundation is devoted to fostering a greater understanding
                    of our youth’s mental health needs through education and
                    inspiration.  By addressing the primary source of mental
                    health problems, we seek to help children, teens and young
                    adults navigate, protect, and sustain healthy minds.
                  </p>
                  <Link
                    className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                    role="button"
                    to="/donate/"
                    style={{ width: 186, height: 54, fontWeight: 600 }}
                  >
                    Donate
                    <img src={HeartIcon} alt="Heart Icon" className="ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </LazyLoadComponent>
        </div>
        <a className="link-unstyled scroll-downs" href="#learn-about-us">
          <div className="mousey">
            <div className="scroller" />
          </div>
          <span
            className="d-block mt-3 small text-white"
            style={{ letterSpacing: "3px" }}
          >
            SCROLL DOWN
          </span>
        </a>
        <div className="background-media hero-bg-image">
          <div className="hero-video-bg d-none">
            <video
              autoPlay
              loop
              muted
              ref={videoRef}
              onCanPlay={() => setPlayBack()}
            >
              {/* <source
          src="https://res.cloudinary.com/dbzxlf4i4/video/upload/v1615355651/waves_zsiwak.mp4"
          type="video/mp4"
        /> */}

              <source src="https://res.cloudinary.com/dbzxlf4i4/video/upload/v1615429279/Fantastic_Wave_Loop_shutterstock_4801631_1_dniczz.mp4" />
            </video>
          </div>
        </div>
      </div>
      <LazyLoadComponent>
        <div id="learn-about-us">
          <div className="container-fluid px-0">
            <div className="row g-0">
              <div className="col-md-4">
                <BgImage image={aboutUsColBg} className="w-100 h-100">
                  <Link
                    to="/about/"
                    className="link-unstyled hero-link-wrapper about-link"
                  >
                    <div className="hero-link-text">
                      <h2 className="display-4 font-weight-bold">About Us</h2>
                      <span className="d-block h4 pt-5">
                        Discover the FLUO Foundation
                      </span>
                      {/* <img
                        className="pt-5 hero-link-arrow"
                        src={CircleRightArrow}
                        alt="Right Arrow"
                        style={{ width: "40px", height: "40px" }}
                      /> */}
                      <div className="pt-5 hero-link-arrow">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="20"
                            cy="20"
                            r="19.5"
                            stroke="white"
                            stroke-opacity="0.5"
                          />
                          <path
                            d="M18 26L24 20L18 14"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                </BgImage>
              </div>
              <div className="col-md-4">
                <BgImage image={whyUsColBg} className="w-100 h-100">
                  <AnchorLink
                    to="/about#our-why"
                    title="Why Us"
                    offset="-3000"
                    className="link-unstyled hero-link-wrapper our-why-link"
                  >
                    <div className="hero-link-text">
                      <h2 className="display-4 font-weight-bold">Our Why</h2>
                      <span className="d-block h4 pt-5">What We Do</span>
                      <div className="pt-5 hero-link-arrow">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="20"
                            cy="20"
                            r="19.5"
                            stroke="white"
                            stroke-opacity="0.5"
                          />
                          <path
                            d="M18 26L24 20L18 14"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </AnchorLink>
                </BgImage>
              </div>
              <div className="col-md-4">
                <BgImage image={ourEmblemColBg} className="w-100 h-100">
                  <AnchorLink
                    to="/about#emblem"
                    // offset="-1500"
                    // title="Why Us"
                    className="link-unstyled hero-link-wrapper emblem-link"
                    // stripHash
                  >
                    <div className="hero-link-text">
                      <h2 className="display-4 font-weight-bold">Our Emblem</h2>
                      <span className="d-block h4 pt-5">
                        Power of Symbology
                      </span>
                      <div className="pt-5 hero-link-arrow">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="20"
                            cy="20"
                            r="19.5"
                            stroke="white"
                            stroke-opacity="0.5"
                          />
                          <path
                            d="M18 26L24 20L18 14"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </AnchorLink>
                </BgImage>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent>
        <div
          className="py-spacing"
          style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
        >
          <div className="container">
            <div className="row justify-content-between responsive-gutter">
              <div className="col-xl-7 col-lg-12 col-md-12">
                <div className="d-grid gap-5">
                  <h2
                    className="display-4 bordered-heading"
                    style={{ fontWeight: 700 }}
                  >
                    Featured
                  </h2>
                  {/* {posts.slice(0, 3).map(({ node }) => ( */}
                  {/* {filterResearch[0].edges.slice(0, 5).map(({ node }) => ( */}
                  {featuredPosts && featuredPosts.map(( node, index ) => (
                    <div className="blog-item blog-item-overlay-text d-grid" key={index}>
                      <Link
                        to={`/${node?.slug?.current}`}
                        className="link-unstyled d-grid"
                      >
                        <div className="blog-image-wrapper image-wrapper-rectangle rounded-25">
                          <GatsbyImage
                            image={
                              node.mainImage
                                ? node.mainImage.asset.gatsbyImageData
                                : ""
                            }
                            style={{
                              gridArea: "1/1",
                              zIndex: 1,
                              height: "100%",
                              borderRadius: 25,
                              position: "absolute",
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              gridArea: "1/1",
                              placeItems: "center",
                            }}
                          />
                          {/* <div
                            className="blog-item-type"
                            title="video"
                            // style={{ zIndex: 3 }}
                          >
                            <img src={VideoIcon} alt="Video Icon" />
                          </div> */}
                        </div>
                      </Link>
                      <div className="blog-text">
                        <div className="blog-item-categories">
                          {node?.categories?.map(cat => (
                            <Link
                              to={`/category/${slugify(
                                cat.title.toLowerCase()
                              )}`}
                              className="blog-category-tag"
                            >
                              <span
                                className={`category-color ${(cat.title ===
                                  "Business" &&
                                  "business-color") ||
                                  (cat.title === "Thought Provoking" &&
                                    "thought-color") ||
                                  (cat.title === "Newsworthy" &&
                                    "news-color") ||
                                  (cat.title === "Education" && "edu-color") ||
                                  (cat.title === "Featured" && "feat-color") ||
                                  (cat.title === "Founder Perspective" &&
                                    "founder-color") ||
                                  (cat.title === "Health & Wellness" &&
                                    "health-color") ||
                                  (cat.title === "Inspiration" &&
                                    "inspi-color") ||
                                  "default-color"}`}
                              />
                              {cat.title}
                            </Link>
                          ))}
                        </div>
                        <Link
                          to={`/${node.slug?.current}`}
                          className="link-unstyled"
                        >
                          <h3 className="h4 mt-2 mb-3 font-weight-semibold blog-item-title">
                            {node.title}
                          </h3>
                        </Link>
                        <span className="d-block small blog-item-details">
                          {node.authors.length !== 0 ? "Posted by" : ""}
                          {node.authors.map(author => (
                            <Link
                              to={`/author/${author?.person?.slug.current}`}
                              className="link-unstyled me-2"
                              style={{ fontWeight: 700 }}
                            >
                              <span className="ps-2 pe-2">
                                {author?.person?.name}
                              </span>
                              <span>•</span>
                            </Link>
                          ))}
                          {node?.publishedAt ? (
                            <div className="d-inline-block">
                              <span>{node?.publishedAt}</span>
                            </div>
                          ) : null}
                          {node?.minutes ? (
                            <span className="ms-2">
                              <span className="me-2">•</span>`{node?.minutes}
                              min read`
                            </span>
                          ) : null}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <Link
                  to="/blog"
                  className="btn btn-primary mt-5"
                  role="button"
                >
                  View All Posts
                </Link>
              </div>
              <div className="col-xl-5 col-lg-12 col-md-12 sidebar-column">
                <div className="d-grid gap-5 pt-mobile-spacing">
                  <h2
                    className="display-6 bordered-heading"
                    style={{ fontWeight: "bold" }}
                  >
                    Categories
                  </h2>
                  <div className="d-grid gap-3">
                    {featuredCat.map(featCat => (
                      <Link
                        to={`/category/${slugify(featCat.title.toLowerCase())}`}
                        className="category-banner-link link-unstyled"
                      >
                        {(featCat.title === "Thought Provoking" && (
                          <span className="thought-color">
                            <StaticImage
                              style={{
                                gridArea: "1/1",
                                zIndex: 1,
                                borderRadius: 10,
                                content: "",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              layout="fullWidth"
                              placeholder="blurred"
                              // aspectRatio={3 / 1}
                              alt="Category Banner"
                              src={"../images/pexels-burst-374720.jpg"}
                              formats={["auto", "webp", "avif"]}
                            />
                          </span>
                        )) ||
                          (featCat.title === "Founder Perspective" && (
                            <span className="founder-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/founder.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Newsworthy" && (
                            <span className="news-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={
                                  "../images/pexels-daria-shevtsova-1928151.jpeg"
                                }
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Education" && (
                            <span className="edu-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/educ.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Inspiration" && (
                            <span className="inspi-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/inspiration.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Health & Wellness" && (
                            <span className="health-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/health.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Featured" && (
                            <span className="feat-color">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/featured.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title === "Newsworthy" && (
                            <span className="news-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={
                                  "../images/pexels-daria-shevtsova-1928151.jpeg"
                                }
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          )) ||
                          (featCat.title && (
                            <span className="business-cat">
                              <StaticImage
                                style={{
                                  gridArea: "1/1",
                                  zIndex: 1,
                                  borderRadius: 10,
                                  content: "",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                }}
                                layout="fullWidth"
                                placeholder="blurred"
                                // aspectRatio={3 / 1}
                                alt="Category Banner"
                                src={"../images/pexels-burst-374720.jpg"}
                                formats={["auto", "webp", "avif"]}
                              />
                            </span>
                          ))}
                        <div
                          style={{
                            gridArea: "1/1",
                            placeItems: "center",
                          }}
                        />
                        <div className="blog-category-tag">
                          <Link
                            to={`/category/${slugify(
                              featCat.title.toLowerCase()
                            )}`}
                          >
                            <span
                              className={`category-color ${(featCat.title ===
                                "Business" &&
                                "business-color") ||
                                (featCat.title === "Thought Provoking" &&
                                  "thought-color") ||
                                (featCat.title === "Newsworthy" &&
                                  "news-color") ||
                                (featCat.title === "Education" &&
                                  "edu-color") ||
                                (featCat.title === "Featured" &&
                                  "feat-color") ||
                                (featCat.title === "Founder Perspective" &&
                                  "founder-color") ||
                                (featCat.title === "Health & Wellness" &&
                                  "health-color") ||
                                (featCat.title === "Inspiration" &&
                                  "inspi-color") ||
                                "default-color"}`}
                            />
                            {featCat.title}
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="d-grid gap-5 pt-spacing">
                    <h2
                      className="display-6 bordered-heading"
                      style={{ fontWeight: 700 }}
                    >
                      Latest posts
                    </h2>
                    {posts.slice(0, 5).map(post => (
                      <div className="blog-item">
                        <div className="blog-text">
                          <div className="blog-item-categories">
                            {post?.node?.categories?.map(cat => (
                              <Link
                                to={`/category/${slugify(
                                  cat.title.toLowerCase()
                                )}`}
                                className="blog-category-tag"
                              >
                                <span
                                  className={`category-color ${(cat.title ===
                                    "Business" &&
                                    "business-color") ||
                                    (cat.title === "Thought Provoking" &&
                                      "thought-color") ||
                                    (cat.title === "Newsworthy" &&
                                      "news-color") ||
                                    (cat.title === "Education" &&
                                      "edu-color") ||
                                    (cat.title === "Featured" &&
                                      "feat-color") ||
                                    (cat.title === "Founder Perspective" &&
                                      "founder-color") ||
                                    (cat.title === "Health & Wellness" &&
                                      "health-color") ||
                                    (cat.title === "Inspiration" &&
                                      "inspi-color") ||
                                    "default-color"}`}
                                />
                                {/* {posts?.node?.categories.title} */}
                                {cat.title}
                              </Link>
                            ))}
                          </div>
                          <Link
                            to={`/${post?.node?.slug?.current}`}
                            className="link-unstyled"
                          >
                            <h3
                              className="h4 mt-2 mb-3 blog-item-title"
                              style={{ fontWeight: 600 }}
                            >
                              {post.node.title}
                            </h3>
                          </Link>
                          <span className="d-block small blog-item-details">
                            {post.node.authors.length !== 0 ? "Posted by" : ""}
                            {post.node.authors.map(author => (
                              <Link
                                to={`/author/${author?.person?.slug.current}`}
                                className="link-unstyled me-2"
                                style={{ fontWeight: 700 }}
                              >
                                <span className="ps-2 pe-2">
                                  {author?.person?.name}
                                </span>
                                <span style={{ color: "#000" }}>•</span>
                              </Link>
                            ))}
                            {post?.node?.publishedAt ? (
                              <div className="d-inline-block">
                                <span>{post?.node?.publishedAt}</span>
                              </div>
                            ) : null}
                            {post?.node?.minutes ? (
                              <span className="ms-2">
                                <span className="me-2">•</span>
                                {post?.node?.minutes} min read
                              </span>
                            ) : null}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="d-grid gap-5 pt-mobile-spacing">
                  <h2
                    className="display-6 bordered-heading"
                    style={{ fontWeight: 700 }}
                  >
                    Latest posts
                  </h2>
                  {posts.slice(0, 5).map(({ node }) => (
                    <div className="blog-item">
                      <div className="blog-text">
                        <div className="blog-item-categories">
                          {node.categories.map(cat => (
                            <Link
                              to={`/category/${slugify(
                                cat.title.toLowerCase()
                              )}`}
                              className="blog-category-tag"
                            >
                              <span
                                className={`category-color ${(cat.title ===
                                  "Business" &&
                                  "business-color") ||
                                  (cat.title === "Creative" &&
                                    "creative-color") ||
                                  (cat.title === "News" && "news-color") ||
                                  (cat.title === "Strategy" &&
                                    "strategy-color") ||
                                  "default-color"}`}
                              />
                              {cat.title}
                            </Link>
                          ))}
                        </div>
                        <Link
                          to={`/${node.slug.current}`}
                          className="link-unstyled"
                        >
                          <h3
                            className="h4 mt-2 mb-3 blog-item-title"
                            style={{ fontWeight: 600 }}
                          >
                            {node.title}
                          </h3>
                        </Link>
                        <span className="d-block small blog-item-details">
                          Posted by&nbsp;
                          {node.authors.map(author => (
                            <Link
                              to={`/author/${author?.person?.slug.current}`}
                              className="link-unstyled"
                              style={{ fontWeight: 700 }}
                            >
                              {author?.person?.name}
                            </Link>
                          ))}
                          • {node.publishedAt} • 5 min read
                        </span>
                      </div>
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
      <LazyLoadComponent>
        <div
          className="crisis-help-lines-section py-spacing"
          style={{ paddingTop: "10rem", paddingBottom: "10rem" }}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-4 col-lg-5 mb-4">
                <h2
                  className="display-5 text-white"
                  style={{ fontWeight: 700 }}
                >
                  Crisis Help Lines
                </h2>
                <p
                  className="my-5"
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  The FLUO Foundation is NOT a crisis line. If you or someone
                  you know is in crisis and needs immediate assistance, call
                  these help lines.
                </p>
                <span
                  className="
                text-white
                bg-danger
                p-1
                small
                text-uppercase
              "
                  style={{ borderRadius: "25px", fontWeight: 600 }}
                >
                  In Case of Emergency
                </span>
                <p className="lead">
                  <a href="tel:911" style={{ fontWeight: 700 }}>
                    Call 911 immediately
                  </a>
                  &nbsp;or go to the nearest hospital emergency room.
                </p>
              </div>
              <div className="col-md-7 col-xl-6">
                <div className="row">
                  <div className="col-md-12 col-lg-6 mb-4">
                    <div
                      className="p-4 h-100 help-line-tile coloradocs"
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="text-center">
                        <div className="logo-wrapper">
                          {/* <img
                            className="img-fluid"
                            src="../../images/colorado-crisis-services.svg"
                          /> */}
                          <StaticImage
                            src="../images/colorado-crisis-services.svg"
                            alt="logo"
                            placeholder="dominantColor"
                            className="img-fluid"
                            formats={["auto", "webp", "avif"]}
                            // style={{
                            //   width: "290.48px",
                            //   height: "61px",
                            // }}
                          />
                        </div>
                        <h3
                          className="text-uppercase h5 my-3"
                          style={{ fontWeight: 600 }}
                        >
                          Colorado Crisis Services
                        </h3>
                      </div>
                      <ul className="contact-details-list">
                        <li className="phone">
                          <a href="tel:1-844-943-8255">1-844-943-TALK (8255)</a>
                        </li>
                        <li className="website">
                          <a
                            href="https://coloradocrisisservices.org/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            coloradocrisisservices.org
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-4">
                    <div
                      className="p-4 h-100 help-line-tile trevorproject"
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="text-center">
                        <div className="logo-wrapper">
                          <StaticImage
                            src="../images/trevor-project.svg"
                            alt="logo"
                            placeholder="dominantColor"
                            className="img-fluid"
                            formats={["auto", "webp", "avif"]}
                          />
                        </div>
                        <h3
                          className="text-uppercase h5 my-3"
                          style={{ fontWeight: 600 }}
                        >
                          The Trevor Project
                        </h3>
                      </div>
                      <ul className="contact-details-list">
                        <li className="phone">
                          Trevor Lifeline:&nbsp;
                          <a href="tel:1-866-488-7386">1-866-488-7386</a>
                        </li>
                        <li className="chat">
                          TrevorChat: Text "
                          <span className="font-weight-semibold">TREVOR</span>"
                          to&nbsp;<a href="tel:202-304-1200">202-304-1200</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-4">
                    <div
                      className="p-4 h-100 help-line-tile nspl"
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="text-center">
                        <div className="logo-wrapper">
                          <StaticImage
                            src="../images/nspl-logo.png"
                            alt="logo"
                            placeholder="dominantColor"
                            className="mx-auto"
                            formats={["auto", "webp", "avif"]}
                            style={{ width: "85%" }}
                          />
                        </div>
                        <h3
                          className="text-uppercase h5 my-3"
                          style={{ fontWeight: 600 }}
                        >
                          National Suicide Prevention Lifeline
                        </h3>
                      </div>
                      <ul className="contact-details-list">
                        <li className="phone">
                          <a href="tel:1-800-273-8255">1-800-273-TALK (8255)</a>
                        </li>
                        <li className="website">
                          <a
                            href="https://suicidepreventionlifeline.org/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            suicidepreventionlifeline.org
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-4">
                    <div
                      className="p-4 h-100 help-line-tile nspl-esp"
                      style={{ borderRadius: "25px" }}
                    >
                      <div className="text-center">
                        <div className="logo-wrapper">
                          <StaticImage
                            src="../images/youthline-logo.png"
                            alt="logo"
                            placeholder="dominantColor"
                            className="mx-auto"
                            formats={["auto", "webp", "avif"]}
                            style={{ width: "85%", objectFit: "cover" }}
                          />
                        </div>
                        <h3
                          className="text-uppercase h5 my-3"
                          style={{ fontWeight: 600 }}
                        >
                          YouthLine
                        </h3>
                      </div>
                      <ul className="contact-details-list">
                        <li className="phone">
                          <a href="tel:877-968-8491">877-968-8491</a>
                        </li>
                        <li className="sms">
                          <a href="sms:839863">teen2teen to 839863</a>
                        </li>
                        <li className="website">
                          <a
                            href="https://oregonyouthline.org/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://oregonyouthline.org/
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </Layout>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    aboutUsCol: file(relativePath: { eq: "about-tile-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    whyUsCol: file(relativePath: { eq: "our-why-tile-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ourEmblemCol: file(relativePath: { eq: "emblem-tile-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    siteSettings: sanitySiteSettings {
      title
      description
    }
    aboutPage: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
      _rawBody
    }
    allSanityPost(sort: { order: DESC, fields: publishedAt }, limit: 5) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          authors {
            person {
              name
              slug {
                current
              }
            }
          }
          categories {
            featuredCategories
            title
          }
          slug {
            current
          }
          excerpt
          _createdAt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allSanityFeaturedpost {
      edges {
        node {
          title
          post {
            title
            minutes
            slug {
              current
            }
            publishedAt(formatString: "MMMM DD, YYYY")
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              id
              title
            }
          }
        }
      }
    }
    allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        featuredCategories
        title
      }
    }
    categoryLists: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            excerpt

            publishedAt(formatString: "MMMM DD, YYYY")
            categories {
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`
